<script setup>
import Checkbox from '@/Components/Checkbox.vue';
import GuestLayout from '@/Layouts/GuestLayout.vue';
import InputError from '@/Components/InputError.vue';
import InputLabel from '@/Components/InputLabel.vue';
import PrimaryButton from '@/Components/PrimaryButton.vue';
import TextInput from '@/Components/TextInput.vue';
import { Head, Link, useForm } from '@inertiajs/vue3';
import {ref} from "vue";
import SecondaryButton
  from "../../../../vendor/laravel/breeze/stubs/inertia-vue-ts/resources/js/Components/SecondaryButton.vue";

defineProps({
    canResetPassword: {
        type: Boolean,
    },
    status: {
        type: String,
    },
});

const form = useForm({
    phone: '',
    email: '',
    password: '',
    remember: false,
});

const usePhone = ref(true)

const requestOneTimePassword = () => {
  form.post(route('auth.otp.request-token'))
}

const submit = () => {
    form.post(route('login'), {
        onFinish: () => form.reset('password'),
    });
};
</script>

<template>
    <GuestLayout>
        <Head title="Log in" />

        <div v-if="status" class="mb-4 font-medium text-sm text-green-600">
            {{ status }}
        </div>

      <p class="text-gray-900 text-xl font-semibold mb-1">
        {{ $t('login.title') }} 👋
      </p>



      <form @submit.prevent="requestOneTimePassword" v-if="usePhone" class="my-2">

        <p class="mb-4 text-gray-500">{{ $t('login.enter_mobile_to_login')}}</p>

       <div>
         <InputLabel for="phone" :value="$t('forms.mobile')" />

         <TextInput
           id="phone"
           type="tel"
           class="mt-1 block w-full"
           v-model="form.phone"
           required
           autofocus
         />

         <InputError class="mt-2" :message="form.errors.phone" />
       </div>

        <div class="flex items-center justify-end mt-4 w-full">

          <button
            type="button"
            @click="usePhone = !usePhone"
            class="underline text-sm text-gray-600 hover:text-gray-900 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">{{ $t('login.login_with_email')}}</button>

          <PrimaryButton class="ml-4 text-center" :class="{ 'opacity-25': form.processing }" :disabled="form.processing">
            {{$t('buttons.continue')}}
          </PrimaryButton>
        </div>
      </form>

        <form @submit.prevent="submit" class="my-2" v-else>
            <div class="mt-4">
                <InputLabel for="email" :value="$t('forms.email')" />

                <TextInput
                    id="email"
                    type="email"
                    class="mt-1 block w-full"
                    v-model="form.email"
                    required
                    autocomplete="username"
                />

                <InputError class="mt-2" :message="form.errors.email" />
            </div>

          <div class="flex justify-end mt-2">
          <button
            type="button"
            @click="usePhone = !usePhone"
            class=" underline text-sm text-gray-600 hover:text-gray-900 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">{{$t('login.login_with_phone')}}</button>
          </div>

            <div class="">
                <InputLabel for="password" :value="$t('forms.password')" />

                <TextInput
                    id="password"
                    type="password"
                    class="mt-1 block w-full"
                    v-model="form.password"
                    required
                    autocomplete="current-password"
                />

                <InputError class="mt-2" :message="form.errors.password" />
            </div>

            <div class="block mt-4">
                <label class="flex items-center">
                    <Checkbox name="remember" v-model:checked="form.remember" />
                    <span class="ml-2 text-sm text-gray-600">{{ $t('forms.remember_me')}}</span>
                </label>
            </div>

            <div class="flex items-center justify-end mt-4">
                <Link
                    v-if="canResetPassword"
                    :href="route('password.request')"
                    class="underline text-sm text-gray-600 hover:text-gray-900 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  {{ $t('buttons.forgot_password')}}
                </Link>

                <PrimaryButton class="ml-4" :class="{ 'opacity-25': form.processing }" :disabled="form.processing">
                  {{ $t('buttons.log_in')}}
                </PrimaryButton>
            </div>
        </form>
    </GuestLayout>
</template>
